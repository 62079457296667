<template>
  <a-form
    ref="formRef"
    :model="formState" 
    :rules="rules"
    label-align="left" 
    layout="vertical" 
    @finishFailed="handleFinishFailed">
  <br/>
    <a-tabs  v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Titre">
        <a-form-item  name="titleFr">
          <a-input v-model:value="formState.titleFr" placeholder="Titre" />
        </a-form-item>        
      </a-tab-pane>
      <a-tab-pane key="2" tab="عنوان">
        <a-form-item name="titleAr">
          <a-input v-model:value="formState.titleAr" placeholder="عنوان" />
        </a-form-item>        
      </a-tab-pane>
      <a-tab-pane key="3" tab="Title">
        <a-form-item name="titleEn">
          <a-input v-model:value="formState.titleEn" placeholder="Title" />
        </a-form-item>        
      </a-tab-pane>      
  </a-tabs>
    <a-form-item label="Categorie" name="category">
      <a-select 
        v-model:value="formState.category"
      >
        <a-select-option
          v-for="category of categories"
          :key="category._id"
          :value="category._id"
        >
         {{category.title[language[activeKey?parseInt(activeKey)-1:0]]}}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Étiquettes" name="tags">
      <a-select
        v-model:value="formState.tags"
        mode="tags"
        style="width: 100%"
        :token-separators="[',']"
      >
      </a-select>
    </a-form-item>     
  <a-tabs  v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Contenue ">
    <a-form-item name="descFr">
      <quill-editor
       v-model:value="formState.descFr"
       style="height: 200px"
       >
       </quill-editor>
    </a-form-item>      
      </a-tab-pane>
      <a-tab-pane key="2" tab="محتوى">
    <a-form-item name="descAr">
      <quill-editor
       v-model:value="formState.descAr"
       style="height: 200px"
       >
       </quill-editor>
    </a-form-item>     
      </a-tab-pane>
      <a-tab-pane key="3" tab="Content">
    <a-form-item name="descEn">
      <quill-editor
       v-model:value="formState.descEn"
       style="height: 200px"
       >
       </quill-editor>
    </a-form-item>      
      </a-tab-pane>  
  </a-tabs>          
    <a-form-item label="Photo de couverture">
      <a-upload-dragger
      :multiple="false"
      :file-list='covImgFileList'
      :before-upload="beforeUpload"
      :custom-request="()=>{return true}"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </a-form-item>
    <a-form-item label="Attaché des fichiers">
      <a-upload-dragger
      :file-list="files"
      :before-upload="beforeUploadFiles"
      :custom-request="()=>{return true}"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </a-form-item>   
    <br/><br/>
    <a-form-item>
      <button class="btn btn-success btn-with-addon text-nowrap" @click="onSubmit">
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe-plus-circle"></i>
        </span>
        Ajouter actualité
      </button>
    </a-form-item>
  </a-form>
</template>
<script>
/* eslint-disable */
import { quillEditor } from 'vue3-quill'
import { InboxOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { defineComponent, toRaw, ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue';

import ApiClient from "@/services/axios"

export default defineComponent({
  components: {
    InboxOutlined,
    quillEditor,
  },
  props:{
    categories:{
      type:Array,
      default:[]
    }
  },  
  setup(props){
    //defining computed vars
    const { categories } = toRefs(props)
    //defining consts
    const rules = {
      titleFr:[{
          required:true,
          message:"Le titre est obligatoire, au moins en français",
          trigger: 'blur',
        }],                        
      category:[{
        required:true,
        message:"La catégorie est obligatoire",
        trigger: 'blur',
      }]      
    }    
    const language =  ['fr','ar','en']
    const router = useRouter()
    // defining data  vars
    const formState  = reactive({
      titleFr:"",
      category:"",
      titleAr:"",
      titleEn:"",
      descFr:"",
      descAr:"",
      descEn:"",
      tags:[],
    })    
    const sendToSubscribed = ref(false)
    const formRef = ref()
    const activeKey = ref()
    const coverImg = ref("")
    const files = ref([])
    const fileList = ref([])
    const covImgFileList = ref([])

    //  defining functions
    const deletetEmpty = (obj)=>{
      for (const key of Object.keys(obj))
        if (obj[key]=="") 
          delete obj[key]
      return obj
    }
    const sendToSubscribedCallBack = ()=>{ console.log("send to subscribed") }
    const onSubmit = ()=>{
      console.log(toRaw(formState))
      formRef.value
        .validate()
        .then(()=>{
          let tempData = toRaw(formState)
          let data = {
            title:{
              fr:tempData.titleFr,
              en:tempData.titleEn,
              ar:tempData.titleAr,
            },
            desc:{
              fr:tempData.descFr,
              en:tempData.descEn,
              ar:tempData.descAr,             
            },
            category:tempData.category,
            tags:tempData.tags
          }
          data.title = deletetEmpty(data.title)
          data.desc = deletetEmpty(data.desc)
          ApiClient.put("/actualites",{
            data
          })
            .then((res)=>{
              if (coverImg.value) {
                let formData = new FormData();
                formData.append("coverImg", coverImg.value, res.data._id);
                if(files.value.length>0){
                  for (const file of files.value) formData.append("files[]", file, "file");
                }
                ApiClient
                  .patch("/actualites/files/" +  res.data._id, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .catch(function()
                    {message.error("Impossible de télécharger l'image du coverture: ")}
                  ).finally(function(){
                     message.success("Actualité Ajoutée")
                     router.push('/actualites' )
                  })
              }
          })
          .catch(()=>message.error("Impossible d'ajouter l'actualité"))
          .finally(()=>{ if(sendToSubscribed.value) sendToSubscribedCallBack() })        
        })
        .catch((e)=>console.debug(e))
    }
    const beforeUpload = (file,_) =>{
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(
          "Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!"
        );
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("L'image doit être inférieure à 5 Mo!");
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`);
        coverImg.value = file;
        covImgFileList.value = [file]
      }
      return isJpgOrPng && isLt2M;      
    }
    const beforeUploadFiles = (file,_)=>{files.value.push(file);return true}
    const handleFinishFailed = errors => {
      console.log(errors);
    };
    return  {
      handleFinishFailed,
      sendToSubscribed,
      activeKey,
      coverImg,
      language,
      files,
      fileList,
      covImgFileList,
      formState,
      categories,
      rules,
      formRef,
      onSubmit,
      beforeUpload,
      beforeUploadFiles
    }
  }
})
</script>