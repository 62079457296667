<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Gérer les actualités' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <addActualiteForm  :categories="categories"/>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
/* eslint-disable */
import addActualiteForm from '@/components/widgets/Forms/addActualiteForm'
import VbHeadersTag from '@/components/widgets/Headers/Tag'

import ApiClient from "@/services/axios"

export default {
  name: 'VbAjouterPublications',
  components: {
//    previewPub,
    addActualiteForm,
    VbHeadersTag,
//    VbForms5,
  },
  data: function () {
    return {
      activeKey: 1,
      categories:[]
    }
  },  
  created(){
    ApiClient.post("/actualites_cetegories/filter",{
      query:{status:"active"}
    }).then((res)=>{
      this.categories = [...res.data]
    })
  },
    props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: 'Basic',
        }
      },
    },
  },

}
</script>
